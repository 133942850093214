import React from 'react';
import useWindowSize from '../../hooks/useWindowSize';
import { SCREEN_SIZES } from '../../utils/constants';
import Icon from '../../components/common/Icon';

const SocialMedia = (): JSX.Element => {
  const { documentWidth } = useWindowSize();

  return (
    <div className="socialMedia">
      <a
        href="https://www.linkedin.com/company/axon-libreria"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="linkedin"
      >
        <Icon
          className={`in footer${documentWidth <= SCREEN_SIZES.MAX_TABLET ? ' big' : ''}`}
          nameAriaLabel="linkedinIcon"
        />
      </a>
      <a
        href="https://www.pinterest.es/axonlibreria/"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="pinterest"
      >
        <Icon
          className={`pt footer${documentWidth <= SCREEN_SIZES.MAX_TABLET ? ' big' : ''}`}
          nameAriaLabel="pinterestIcon"
        />
      </a>
      <a
        href="https://twitter.com/axonlibreria"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="twitter"
      >
        <Icon
          className={`tw footer${documentWidth <= SCREEN_SIZES.MAX_TABLET ? ' big' : ''}`}
          nameAriaLabel="twitterIcon"
        />
      </a>
      <a
        href="https://www.facebook.com/libreriaaxon"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="facebook"
      >
        <Icon
          className={`fb footer${documentWidth <= SCREEN_SIZES.MAX_TABLET ? ' big' : ''}`}
          nameAriaLabel="facebookIcon"
        />
      </a>
    </div>
  );
};

export default SocialMedia;
