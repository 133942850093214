import React from 'react';
import Icon from '../../../components/common/Icon';

interface TitleBulletProps {
  children: React.ReactNode;
  className?: string;
  iconClass?: string;
}

const TitleBullet = ({
  children,
  className: classes,
  iconClass,
}: TitleBulletProps): JSX.Element => {
  return (
    <span className={`titleBullet ${classes || ''}`}>
      <Icon className={iconClass} />
      <span>{children}</span>
    </span>
  );
};

export default TitleBullet;
