import React from 'react';
import useWindowSize from '../../../hooks/useWindowSize';
import { SCREEN_SIZES } from '../../../utils/constants';
import Icon from '../../../components/common/Icon';
import TitleWarranty from './TitleWarranty';

const Warranties = (): JSX.Element => {
  const { documentWidth } = useWindowSize();

  return (
    <div className="warranties">
      {documentWidth < SCREEN_SIZES.MIN_DESK ? <Icon className="bullet-check" /> : null}
      <TitleWarranty>Garantía Axón</TitleWarranty>
      <TitleWarranty>Conexión 100% Segura</TitleWarranty>
      <TitleWarranty>Confianza Online</TitleWarranty>
    </div>
  );
};

export default Warranties;
