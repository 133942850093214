/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-indent */
import React from 'react';

import Link from './link/Link';
import useWindowSize from '../../hooks/useWindowSize';
import { SCREEN_SIZES } from '../../utils/constants';

interface ContactDataProps {
  footer?: boolean;
  expanded?: boolean;
  postal?: boolean;
  noLinks?: boolean;
  legal?: boolean;
}

const ContactData = ({
  footer,
  expanded,
  postal,
  noLinks,
  legal,
}: ContactDataProps): JSX.Element => {
  const { documentWidth } = useWindowSize();

  if (footer && documentWidth < SCREEN_SIZES.MAX_TABLET) {
    return (
      <div className="contactData desktop">
        <p>
          <span>Tel</span>
          <span>91 593 99 99</span>
        </p>

        {expanded && (
          <p>
            <span>Fax</span>
            <span>91 448 21 88</span>
          </p>
        )}

        <p>
          <span>Dir</span>
          <span>
            C/ Artesanos, 46
            <br />
            Parque Empresarial Prado del Espino
            <br />
            28660 Boadilla del Monte
            <br />
            Madrid
          </span>
        </p>

        <p>
          <span>Mail</span>
          <span>axon@axon.es</span>
        </p>

        {expanded && <p>Privacidad</p>}
        {expanded && <p>Condiciones de venta</p>}
        {expanded && <p>Aviso</p>}
        {expanded && <p>Contacto</p>}
      </div>
    );
  }

  if (postal) {
    return (
      <div className="contactData">
        <p className="center">AXÓN Librería S.L.</p>
        <p className="center">
          C/ Artesanos, 46
          <br />
          Parque Empresarial Prado del Espino
          <br />
          28660 Boadilla del Monte
          <br />
          Madrid
        </p>
      </div>
    );
  }

  return (
    <div className="contactData desktop">
      {legal && (
        <>
          <p>
            <strong>Nombre</strong>
            {` AXÓN Librería S.L.`}
          </p>

          <p>
            <strong>CIF</strong>
            {` B 80790355`}
          </p>
        </>
      )}
      <div>
        <Link
          className={`contactLink phone ${documentWidth < SCREEN_SIZES.MAX_TABLET ? 'pink' : ''}`}
          href="tel:003491 593 99 99"
        >
          <>
            <strong>Tel </strong>
            91 593 99 99
          </>
        </Link>

        <p className="contactLink phone">
          <strong style={{ marginRight: '7px' }}>Fax</strong> 91 448 21 88
        </p>

        <Link
          className={`contactLink addressFooter ${
            documentWidth < SCREEN_SIZES.MAX_TABLET ? 'pink' : null
          }`}
          href="/contacto"
          rel="nofollow"
        >
          <strong>Dir</strong>
          <p>
            C/ Artesanos, 46
            <br />
            Parque Empresarial Prado del Espino
            <br />
            28660 Boadilla del Monte
            <br />
            Madrid
          </p>
        </Link>

        <Link
          className={`contactLink spacer ${documentWidth < SCREEN_SIZES.MAX_TABLET ? 'pink' : ''}`}
          href="mailto:info@axon.es"
          avoidBlink
        >
          <>
            <strong>Mail</strong>
            info@axon.es
          </>
        </Link>
      </div>

      {!noLinks && (
        <div>
          <Link
            className={`contactLink ${documentWidth < SCREEN_SIZES.MAX_TABLET ? 'pink' : ''}`}
            href="/privacidad"
            rel="nofollow"
            aria="privacidad"
          >
            Privacidad
          </Link>

          <Link
            className={`contactLink ${documentWidth < SCREEN_SIZES.MAX_TABLET ? 'pink' : ''}`}
            href="/condiciones"
            rel="nofollow"
            aria="condiciones"
          >
            Condiciones de venta
          </Link>

          <Link
            className={`contactLink ${documentWidth < SCREEN_SIZES.MAX_TABLET ? 'pink' : ''}`}
            href="/quienes-somos"
            rel="nofollow"
            aria="quienes-somos"
          >
            Quiénes Somos
          </Link>

          <Link
            className={`contactLink ${documentWidth < SCREEN_SIZES.MAX_TABLET ? 'pink' : ''}`}
            href="/aviso-legal"
            rel="nofollow"
            aria="aviso"
          >
            Aviso
          </Link>

          <Link
            className={`contactLink ${documentWidth < SCREEN_SIZES.MAX_TABLET ? 'pink' : ''}`}
            href="/contacto"
            rel="nofollow"
            aria="contacto"
          >
            Contacto
          </Link>
        </div>
      )}
    </div>
  );
};

export default ContactData;
