import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

import PaymentMethod from './PaymentMethod';
import SocialMedia from './SocialMedia';
import Warranties from './warranties/Warranties';
import useWindowSize from '../../hooks/useWindowSize';
import Image from '../../components/common/Image';
import { currentVersion, SCREEN_SIZES } from '../../utils/constants';
import ContactData from '../../components/common/ContactData';

interface FooterProps {
  closeToEnd?: boolean;
}

const Footer = ({ closeToEnd }: FooterProps): JSX.Element => {
  const router = useRouter();
  const { documentWidth } = useWindowSize();
  const type = router?.pathname;
  const [footerExpanded, setFooterExpanded] = useState<boolean>(closeToEnd);

  useEffect(() => {
    setFooterExpanded(closeToEnd);
  }, [closeToEnd]);

  if (type !== null && type.indexOf('/areas') === 0) {
    return null;
  }
  if (documentWidth > SCREEN_SIZES.MAX_TABLET) {
    return (
      <div className="footer">
        <div className="footerInner">
          <div className="separatorTop" />

          <Warranties />
          <ContactData expanded={false} footer />
          <PaymentMethod />
          <SocialMedia />

          <div className="separatorBottom" />

          <div className="copyRight">
            <p>{`© ${new Date().getFullYear()} Axón Librería S.L.`}</p>
            <p>{`${currentVersion}`}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="footer">
      <div className="separatorTop" />
      {footerExpanded ? (
        <div className="footerInner expanded">
          <Image
            className="logo"
            addCdnPrefix
            src="img/logo-footer-mobile.svg"
            alt="logo"
            width="20"
            height="20"
          />
          <Warranties />
          <div className="divider" />
          <ContactData expanded={footerExpanded} />
          <div className="divider" />
          <PaymentMethod />
          <div className="divider" />
          <SocialMedia />
          <div className="copyRight">
            <p>{`© ${new Date().getFullYear()} Axón Librería S.L.`}</p>
            <p>{`${currentVersion}`}</p>
          </div>
          <div className="separator" />
        </div>
      ) : (
        <div className="footerInner">
          <Image
            className="logo"
            addCdnPrefix
            src="img/logo-footer-mobile.svg"
            alt="logo"
            width="20"
            height="20"
          />
          <ContactData expanded={footerExpanded} />
          <div className="divider" />
          <SocialMedia />
          <div className="copyRight">
            <p>{`© ${new Date().getFullYear()} Axón Librería S.L.`}</p>
            <p>{`${currentVersion}`}</p>
          </div>
          <div className="separator" />
        </div>
      )}
      <div className="separatorBottom" />
    </div>
  );
};

export default Footer;
