import React from 'react';

import { SCREEN_SIZES } from '../../utils/constants';
import useWindowSize from '../../hooks/useWindowSize';
import Image from '../../components/common/Image';

const PaymentMethod = (): JSX.Element => {
  const { documentWidth } = useWindowSize();

  return (
    <div className="paymentMethod">
      <Image
        className="mastercard"
        src={
          documentWidth > SCREEN_SIZES.MAX_TABLET
            ? 'img/logo-color-mastercard.svg'
            : 'img/logo-footer-mastercard.svg'
        }
        addCdnPrefix
        alt="mastercard"
        width="100"
        height="30"
      />
      <Image
        className="mastercard"
        src={
          documentWidth > SCREEN_SIZES.MAX_TABLET
            ? 'img/logo-color-maestro.svg'
            : 'img/logo-footer-maestro.svg'
        }
        addCdnPrefix
        alt="mastercard"
        width="90"
        height="30"
      />
      <Image
        className="paypal"
        src={
          documentWidth > SCREEN_SIZES.MAX_TABLET
            ? 'img/logo-color-paypal.svg'
            : 'img/logo-footer-paypal.svg'
        }
        addCdnPrefix
        alt="paypal"
        width="70"
        height="30"
      />
      <Image
        className="visa"
        src={
          documentWidth > SCREEN_SIZES.MAX_TABLET
            ? 'img/logo-color-visa.svg'
            : 'img/logo-footer-visa.svg'
        }
        addCdnPrefix
        alt="visa"
        width="40"
        height="30"
      />
      <Image
        className="american"
        src={
          documentWidth > SCREEN_SIZES.MAX_TABLET
            ? 'img/logo-color-american-express.svg'
            : 'img/logo-footer-american-express.svg'
        }
        addCdnPrefix
        alt="american"
        width="30"
        height="30"
      />
    </div>
  );
};

export default PaymentMethod;
