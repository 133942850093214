import React from 'react';
import TitleBullet from './TitleBullet';
import useWindowSize from '../../../hooks/useWindowSize';
import { SCREEN_SIZES } from '../../../utils/constants';

interface TitleWarratyProps {
  children?: React.ReactNode;
}

const TitleWarraty = ({ children }: TitleWarratyProps): JSX.Element => {
  const { documentWidth } = useWindowSize();

  if (documentWidth > SCREEN_SIZES.MAX_TABLET) {
    return (
      <TitleBullet className="warranty" iconClass="bullet-check">
        {children}
      </TitleBullet>
    );
  }
  return <p className="warranty">{children}</p>;
};

export default TitleWarraty;
